import React from "react"
import PropTypes from "prop-types"

import Container from '@material-ui/core/Container';
import SiteAppBar from '../components/navigation/SiteAppBar'
import Footer from '../components/navigation/Footer'

import useMediaQuery from '@material-ui/core/useMediaQuery';

const Layout = ({ children }) => {

  let containerPaddingTop = 64

  const bolMin600 = useMediaQuery('(min-width:600px)');
  if (!bolMin600) {
    containerPaddingTop = 56
  }

  return (
    <Container disableGutters maxWidth="xl">
      <SiteAppBar />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1920,
          paddingTop: containerPaddingTop
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </Container>
  )

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
