import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "gatsby"

// ICONS
import MailIcon from '@material-ui/icons/Mail';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const menuLinkStyle = {
    color: "#231B1D", 
    textDecoration: "none"
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

        <Link to="/" style={menuLinkStyle}>
          <ListItem button>
            <ListItemIcon><AccountCircleSharpIcon /></ListItemIcon>
            <ListItemText primary="About" />
          </ListItem>
        </Link>

        <Link to="/projects" style={menuLinkStyle}>
          <ListItem button>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
        </Link>

        <Link to="https://educate.scuffdesign.co" target="_blank" style={menuLinkStyle}>
          <ListItem button>
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItem>
        </Link>

      </List>

      <Divider />

      <List>

        <a href="mailto:admin@kyleroberts.tech" style={menuLinkStyle}>
          <ListItem button>
            <ListItemIcon><MailIcon /></ListItemIcon>
            <ListItemText primary="Send Email" />
          </ListItem>
        </a>

      </List>
    </div>
  );
  
  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}